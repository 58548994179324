<template>
  <div data-app>
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-body pt-3 pb-0">
        <div class="row">
          <div
            class="col-md-2"
            v-if="Number(currentUserPersonalInfo.perfil_id) === 1"
          >
            <v-autocomplete
              v-model="mandante"
              :items="mandantes"
              item-value="mandanteID"
              item-text="mandanteNombre"
              @change="getCampaigns"
              label="Mandante"
              dense
            ></v-autocomplete>
          </div>
          <div class="col-md-3">
            <v-autocomplete
              v-model="campaign"
              :items="campaigns"
              label="Campaña"
              item-key="campaignID"
              item-value="campaignID"
              item-text="nombre"
              dense
            ></v-autocomplete>
          </div>
          <div class="col-md-2">
            <v-menu
              ref="datepickerDesde"
              v-model="datepickerDesde"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fechaDesde"
                  label="Desde"
                  v-bind="attrs"
                  v-on="on"
                  class="m-0 p-0"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fechaDesdeOriginal"
                @input="datepickerDesde = false"
                :max="new Date().toISOString().slice(0, 10)"
                no-title
              ></v-date-picker>
            </v-menu>
          </div>
          <div class="col-md-2">
            <v-menu
              ref="datepickerHasta"
              v-model="datepickerHasta"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fechaHasta"
                  label="Hasta"
                  v-bind="attrs"
                  v-on="on"
                  class="m-0 p-0"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fechaHastaOriginal"
                @input="datepickerHasta = false"
                :max="new Date().toISOString().slice(0, 10)"
                no-title
              ></v-date-picker>
            </v-menu>
          </div>
          <div class="col-md-3">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-on:click="showManagements()"
            >
              Filtrar
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <v-row v-if="dialogShowManagement">
      <CampaignDetail
        :campaignData="campaignData"
        :jobs="jobs"
      ></CampaignDetail>
      <v-col cols="12">
        <div class="card card-custom card-stretch">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Gestiones</h3>
            </div>
          </div>
          <div class="card-body p-5 pb-7">
            <v-row>
              <v-col cols="12">
                <v-btn
                  color="primary float-right"
                  dark
                  class="mb-2"
                  v-on:click="
                    downloadExcelFile(
                      'gestiones',
                      'management_table',
                      headersManagements.length
                    )
                  "
                >
                  Exportar
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  :headers="headersManagements"
                  :items="managements"
                  class="table-striped elevation-1"
                  hide-default-footer
                  disable-pagination
                  id="management_table"
                >
                </v-data-table>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import moment from "moment";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import {
  GET_CAMPAIGNS_MANDANTE,
  GET_ALL_CAMPAIGN_DATA,
} from "@/core/services/store/campaign.module";
import { GET_MANDANTES } from "@/core/services/store/mandante.module";
import { GET_COMPLEATED_JOBS } from "@/core/services/store/task.module";
import { mapGetters } from "vuex";
import XLSX from "sheetjs-style";
import Swal from "sweetalert2";
import CampaignDetail from "../components/campaign/CampaignDetail.vue";
import { getInfoXLSXCampaign } from "../../helpers/helper";
export default {
  name: "managements",
  components: {
    //eslint-disable-next-line
    draggable,
    CampaignDetail,
  },
  data() {
    return {
      dialogShowManagement: false,
      campaign: null,
      campaigns: [],
      mandante: null,
      mandantes: [],
      fechaDesde: null,
      fechaDesdeOriginal: null,
      datepickerDesde: false,
      fechaHasta: null,
      fechaHastaOriginal: null,
      datepickerHasta: false,
      search: "",
      campaignData: {
        campaignID: null,
        nombre: "",
        estado: "",
        fechaInicio: "",
        fechaTermino: "",
        cantidadTareasAsignadas: 0,
        cantidadTareasRealizadas: 0,
        tareas: [],
      },
      jobs: [],
      headersManagements: [],
      managements: [],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Gestiones", route: "managements" },
    ]);
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getMandantes();
      // this.campaign = 93;
      // this.showCampaign();
    },
    getMandantes() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.mandantes = [];
      oVue.$store.dispatch(GET_MANDANTES).then((mandantes) => {
        oVue.mandantes = mandantes;
        if (Number(oVue.currentUserPersonalInfo.perfil_id) === 2) {
          oVue.mandante = oVue.currentUserPersonalInfo.mandanteID;
          oVue.getCampaigns();
        }
        if (!oVue.params) {
          KTUtil.removeLoading();
        }
      });
    },
    getCampaigns() {
      let oVue = this;
      oVue.campaigns = [];
      if (oVue.mandante) {
        KTUtil.setLoading();
        oVue.$store
          .dispatch(GET_CAMPAIGNS_MANDANTE, oVue.mandante)
          .then((campaigns) => {
            oVue.campaigns = campaigns;
            if (!oVue.params) {
              KTUtil.removeLoading();
            }
          });
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    async showManagements() {
      let oVue = this;
      oVue.dialogShowManagement = false;

      if (!oVue.campaign) {
        Swal.fire({
          title: "Error!",
          text: "Debe seleccionar una campaña",
          icon: "warning",
        });
        return;
      }

      if (!oVue.fechaDesde || !oVue.fechaHasta) {
        Swal.fire({
          title: "Error!",
          text: "Debe seleccionar las fechas",
          icon: "warning",
        });
        return;
      }

      const oMomentDesde = moment(oVue.fechaDesdeOriginal);
      const oMomentHasta = moment(oVue.fechaHastaOriginal);

      if (oMomentDesde.diff(oMomentHasta) > 0) {
        Swal.fire({
          title: "Error!",
          text: "La fecha desde no puede ser mayor a la fecha hasta",
          icon: "warning",
        });
        return;
      }

      oVue.headersManagements = [];
      oVue.managements = [];

      const aAllCampaignDataResponse = await oVue.getAllCampaignData(
        oVue.campaign
      );
      if (aAllCampaignDataResponse.result) {
        const campaignData = aAllCampaignDataResponse.data.general;
        oVue.campaignData = campaignData;
        const aJobs = aAllCampaignDataResponse.data.jobs;
        oVue.jobs = aJobs;
        const aAllManagementDataResponse = await oVue.getAllManagementData();

        if (aAllManagementDataResponse) {
          const managementData = aAllManagementDataResponse.data;
          if (managementData.length == 0) {
            return;
          }
          const headersManagementsData = managementData[0].datos;
          if (headersManagementsData) {
            let headersManagements = [];
            headersManagements.push(
              {
                text: "Tarea",
                value: "taskID",
              },
              {
                text: "Fecha Gestión",
                value: "fechaGestion",
              },
              {
                text: "Jobber",
                value: "jobber",
              }
            );
            headersManagements = headersManagements.concat(
              headersManagementsData.map((h) => {
                return {
                  text: h.titulo,
                  value: `campo_${h.id}`,
                };
              })
            );
            headersManagements.push(
              {
                text: "Accion",
                value: "accion",
              },
              {
                text: "Resultado",
                value: "resultado",
              },
              {
                text: "Pregunta",
                value: "pregunta",
              },
              {
                text: "Respuesta",
                value: "respuesta",
              },
              {
                text: "Tipo de Gestión",
                value: "tipoGestion",
              },
              {
                text: "Descripción",
                value: "descripcion",
              },
              {
                text: "Clasificación",
                value: "clasificacion",
              }
            );
            oVue.headersManagements = headersManagements;
            let aManagements = [];
            let codigo1,
              codigo2,
              tipoGestion,
              descripcion,
              clasificacion = "";
            let iCount = 0;
            managementData.forEach((m) => {
              m.preguntas.forEach((p) => {
                let aManagement = {};
                aManagement.id = iCount;
                aManagement.taskID = m.id;
                aManagement.fechaGestion = moment(m.fechaGestion).format(
                  "DD-MM-YYYY HH:mm:ss"
                );
                aManagement.jobber = m.jobber;
                m.datos.forEach((d) => {
                  aManagement[`campo_${d.id}`] = d.respuesta;
                });
                if (p.codigo) {
                  const oCodigo = p.codigo;
                  codigo1 = oCodigo.codigo1;
                  codigo2 = oCodigo.codigo2;
                  tipoGestion = oCodigo.tipo;
                  descripcion = oCodigo.descripcion;
                  clasificacion = oCodigo.prioridad;
                } else {
                  codigo1 =
                    codigo2 =
                    tipoGestion =
                    descripcion =
                    clasificacion =
                      "";
                }
                aManagement.accion = codigo1;
                aManagement.resultado = codigo2;
                aManagement.pregunta = p.titulo;
                aManagement.respuesta = p.respuesta;
                aManagement.tipoGestion = tipoGestion;
                aManagement.descripcion = descripcion;
                aManagement.clasificacion = clasificacion;
                aManagements.push(aManagement);
                iCount++;
              });
            });
            oVue.managements = aManagements;
          }

          oVue.dialogShowManagement = true;
        }
      }
    },
    async getAllCampaignData(sCampaignNombre) {
      KTUtil.setLoading();
      let oVue = this;
      const aAllCampaignDataResponse = await oVue.$store.dispatch(
        GET_ALL_CAMPAIGN_DATA,
        { campaign: sCampaignNombre }
      );
      KTUtil.removeLoading();

      return aAllCampaignDataResponse;
    },
    async getAllManagementData() {
      KTUtil.setLoading();
      let oVue = this;
      const oData = {
        campaign: oVue.campaign,
        mandante: oVue.mandante,
        fechaDesde: oVue.fechaDesdeOriginal,
        fechaHasta: oVue.fechaHastaOriginal,
      };
      const aAllManagementDataResponse = await oVue.$store.dispatch(
        GET_COMPLEATED_JOBS,
        oData
      );
      KTUtil.removeLoading();

      return aAllManagementDataResponse;
    },
    downloadExcelFile(filename, id, cols) {
      let oVue = this;
      const wb = (XLSX.WorkBook = XLSX.utils.book_new());

      //Datos de la campaña
      const infoXLSXCampaign = getInfoXLSXCampaign(oVue.campaignData);
      const ws_campaign_name = "campaña";
      const ws_campaign = XLSX.utils.aoa_to_sheet(infoXLSXCampaign);
      wb.SheetNames.push(ws_campaign_name);
      wb.Sheets[ws_campaign_name] = ws_campaign;

      //Datos personalizados
      const elt = KTUtil.getById(id);
      const table = elt.querySelector("table");
      const ws = (XLSX.WorkSheet = XLSX.utils.table_to_sheet(table, {
        raw: true,
      }));

      let row = 0;
      for (let col = 0; col <= cols; col++) {
        const ref = XLSX.utils.encode_cell({ r: row, c: col });
        if (ws[ref]) {
          ws[ref].s = {
            font: {
              bold: true,
            },
          };
        }
      }

      XLSX.utils.book_append_sheet(wb, ws, filename);

      return XLSX.writeFile(wb, `${filename}.xlsx`);
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    //eslint-disable-next-line no-unused-vars
    fechaDesdeOriginal(val) {
      this.fechaDesde = this.formatDate(this.fechaDesdeOriginal);
    },
    //eslint-disable-next-line no-unused-vars
    fechaHastaOriginal(val) {
      this.fechaHasta = this.formatDate(this.fechaHastaOriginal);
    },
  },
};
</script>
